import http from "../common/http.js"

export default{
  getChart(width,height,dataCount){
    return http.get(`/fdcinfo/chart`,{params:{width,height,dataCount}});

  },


}

